import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EditOperatorComponent } from '../../../components/modals/edit-operator/edit-operator.component';
import { SnackService } from '../../../services/helpers/snack.service';

@Component({
  selector: 'lib-increase-passengers-number',
  templateUrl: './increase-passengers-number.component.html',
  styleUrls: ['./increase-passengers-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncreasePassengersNumberComponent {
  tabForm = this.fb.group({
    type: [ 'adult', [ Validators.required, Validators.maxLength(30) ] ],
  });
  types = ['adult', 'child', 'infant', 'pet', ];
  constructor(
    public dialogRef: MatDialogRef<EditOperatorComponent>,
    private fb: UntypedFormBuilder,
    private snack: SnackService,
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.close();
    });
  }
  close(): void {
    this.dialogRef.close();
  }
}
