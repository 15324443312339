import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightDetailsRoutingModule } from './flight-details-routing.module';
import { FlightDetailsComponent } from './flight-details.component';
import { GlobalSharedModule } from '../../global-shared.module';
import { IncreasePassengersNumberComponent } from './increase-passengers-number/increase-passengers-number.component';


@NgModule({
  declarations: [
    FlightDetailsComponent,
    IncreasePassengersNumberComponent,
  ],
  imports: [
    CommonModule,
    FlightDetailsRoutingModule,
    GlobalSharedModule,
  ]
})
export class FlightDetailsModule { }
