import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl, FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime, Duration } from 'luxon';
import { debounceTime, first, map, Observable, tap } from 'rxjs';
import { BidToCreate } from '../../classes/BidToCreate';
import { BidToUpdate } from '../../classes/BidToUpdate';
import { BookingToUpdate } from '../../classes/BookingToUpdate';
import { AircraftSavedDetailsComponent } from '../../components/modals/aircraft-saved-details/aircraft-saved-details.component';
import { ConfirmDeactivateGuard } from '../../guards/confirm-deactivate.guard';
import { AircraftSaved } from '../../interfaces/AircraftSaved';
import { Airport } from '../../interfaces/Airport';
import { Bid } from '../../interfaces/Bid';
import { EstimatedLeg, EstimatedLegByCategory } from '../../interfaces/EstimatedLeg';
import { Leg } from '../../interfaces/Leg';
import { Offer } from '../../interfaces/Offer';
import { Operator } from '../../interfaces/Operator';
import { User } from '../../interfaces/User';
import { AircraftsSavedService } from '../../services/api/aircrafts-saved.service';
import { AirportsService } from '../../services/api/airports.service';
import { BidsService } from '../../services/api/bids.service';
import { BookingsService } from '../../services/api/bookings.service';
import { ErsService } from '../../services/api/ers.service';
import { FrpsService } from '../../services/api/frps.service';
import { OffersService } from '../../services/api/offers.service';
import { OperatorsService } from '../../services/api/operators.service';
import { UserService } from '../../services/api/user.service';
import { AirportDatetimeService } from '../../services/helpers/airport-datetime.service';
import { PlatformService } from '../../services/helpers/platform.service';
import { SnackService } from '../../services/helpers/snack.service';
import { TimeService } from '../../services/helpers/time.service';
import { ActionAlertComponent } from '../../components/modals/action-alert/action-alert.component';
import { GetOperatorEmailAndPhone } from '../../pipes/GetOperatorEmailAndPhone';

@Component({
  selector: 'lib-make-offer',
  templateUrl: './make-offer.component.html',
  styleUrls: ['./make-offer.component.scss']
})
export class MakeOfferComponent implements OnInit, OnDestroy, ConfirmDeactivateGuard {
  curUser!: User;
  legs!: Leg[];
  otherOffersBids!: Array<{ offer: string; bids: Bid[]; }>;
  operators!: Operator[];
  operatorsFiltered!: Operator[];
  aircraftsSaved: Array<AircraftSaved> = [];

  estimatedFlightTime!: Array<{ [x: string]: string; }>;

  filteredFromAirports: Array<Airport> = [];
  filteredToAirports: Array<Airport> = [];

  requesting = false;
  isLoading = false;
  isEditing = false;
  frpId?: string;
  offerId!: string;
  bookingId!: string;
  submitState = false;
  successState = false;
  step = 100;
  totalBudget = 0;
  totalTime = '00:00';
  errorMsg = '';
  onScrollOne = false;
  onScrollTwo = false;
  hideFeaturedDealButton = true;
  bidsList = new UntypedFormArray([]);
  bidsForm = new UntypedFormGroup({
    operator: new UntypedFormControl(undefined, Validators.required),
    email: new UntypedFormControl({ value: '', disabled: true }),
    phone: new UntypedFormControl({ value: '', disabled: true }),
    terms: new UntypedFormControl(''),
    bidsList: this.bidsList,
  });
  items: Array<Leg | Bid> = [];
  offers?: Offer[];
  tab = new FormControl('make-offer');
  isAuthorized?: boolean;
  isMobile$: Observable<boolean>;
  @ViewChild('scrollOne', { static: false }) public scrollOne!: ElementRef;
  @ViewChild('scrollTwo', { static: false }) public scrollTwo!: ElementRef;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.bidsForm.dirty || this.isEditing;
  }

  constructor(
    private dialog: MatDialog,
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private bidsService: BidsService,
    private aircraftsSavedService: AircraftsSavedService,
    private airportDatetimeSrv: AirportDatetimeService,
    public timeService: TimeService,
    private airportsService: AirportsService,
    private snack: SnackService,
    public userService: UserService,
    private metaService: Meta,
    private frpService: FrpsService,
    private bookingService: BookingsService,
    private offersService: OffersService,
    private operatorService: OperatorsService,
    private ersService: ErsService,
    private platformService: PlatformService,
    private getOperatorEmailAndPhonePipe: GetOperatorEmailAndPhone
  ) {
    this.metaService.addTag({ name: 'theme-color', content: '#101528' });
    //document.documentElement.classList.add('ofy-hide');
    //document.body.classList.add('ofy-hide');
    this.isMobile$ = this.platformService.isMobile$;
  }

  ngOnInit(): void {
    this.afAuth.user
      .pipe(first())
      .subscribe(fbuser => {
        this.route.queryParams
          .pipe(first())
          .subscribe(params => {
            if (fbuser?.uid) {
              this.isAuthorized = true;
              this.getUserInfo();
              this.getOperators();
            }
            if (params.request) {
              this.frpId = params.request;
              this.getRequestById(params.request);
              if (this.isAuthorized) this.getOfferByFrpId(params.request);
            }
            if (params.offer) {
              this.offerId = params.offer;
              this.isEditing = true;
              this.getOfferById(params.offer);
            }
            if (params.booking) {
              this.getBookingById(params.booking);
              this.isEditing = true;
              this.bookingId = params['booking'];
            }
          });
        if (this.isEditing) this.setTotalTime();
      });
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=theme-color');
    document.documentElement.classList.remove('ofy-hide');
    document.body.classList.remove('ofy-hide');
  }

  getUserInfo(): void {
    this.userService.getUserInfo()
      .pipe(first())
      .subscribe(resp => {
        this.curUser = resp;
        if (this.userService.isOperator) {
          this.bidsForm.get('operator')?.setValue(resp.operator);
          this.bidsForm.get('operator')?.disable();
        }
      });
  }

  getOfferByFrpId(frpId: string): void {
    if (this.userService.isOperator) {
      this.bidsService.getOtherOffers({ frpid: frpId })
        .pipe(first())
        .subscribe(resp => {
          if (this.offerId) {
            this.offers = resp.filter(offer => offer.offer !== this.offerId);
          } else {
            this.offers = resp;
          }
        });
    } else {
      this.frpService.getOffersByFrpId(frpId)
        .pipe(first())
        .subscribe(resp => {
          if (this.offerId) {
            this.offers = resp.filter(offer => offer.id !== this.offerId);
          } else {
            this.offers = resp;
          }
        });
    }
  }

  getRequestById(id: string): void {
    this.frpService.getFrpById(id)
      .pipe(first())
      .subscribe(resp => {
        this.legs = resp.legs.sort((a, b) => new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime());
        this.items = resp.legs.sort((a, b) => new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime());
        this.initForm();
      });
  }

  getOfferById(id: string): void {
    this.offersService.getOfferById(id)
      .pipe(
        first(),
        tap(resp => this.frpId = resp[0]?.leg.frpId)
      )
      .subscribe(resp => {
        if (this.frpId) this.getOfferByFrpId(this.frpId);
        this.legs = resp
          .map(bid => bid.leg)
          .sort((a, b) => new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime());
        this.items = resp.sort((a, b) => new Date(a.leg.flightDate).getTime() - new Date(b.leg.flightDate).getTime());
        this.initForm();
      });
  }

  getBookingById(id: string): void {
    this.bookingService.getBookingById(id)
      .pipe(first())
      .subscribe(resp => {
        this.frpService.getFrpById(resp.frpId).pipe(first())
        .subscribe(frp => {
          this.legs = (frp.legs as Leg[])
          .sort((a, b) => new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime());
          this.items = resp.bookingLegs.sort((a, b) => new Date(a.takeoffDate).getTime() - new Date(b.takeoffDate).getTime());
          this.initForm();
        })
      });
  }

  getOperators(): void {
    this.operatorService.getOperators()
      .pipe(first())
      .subscribe(operators => {
        this.operators = operators?.filter(operator => operator.active);
        this.operatorsFiltered = this.operators.slice();
      });
  }

  resetPage(): void {
    while (this.bidsList.length !== 0) {
      this.bidsList.removeAt(0);
    }
    this.bidsForm.reset();
    this.ngOnInit();
  }

  initForm(): void {
    this.items.forEach((item, itemIndex: number) => {
      const curFromAirports = new UntypedFormControl(undefined, [Validators.required]);
      const curToAirports = new UntypedFormControl(undefined, [Validators.required]);
      this.bindAirports(curFromAirports, 'from');
      this.bindAirports(curToAirports, 'to');
      if (!(item as Leg).fromCityOnly) curFromAirports.setValue(item.from);
      if (!(item as Leg).toCityOnly) curToAirports.setValue(item.to);
      const curGroup = this.isEditing ? this.fb.group({
        id: [item.id || ''],
        refNumber: [(item as Bid).refNumber || ''],
        offer: [(item as Bid).offer || ''],
        flightDate: [
          new Date(this.airportDatetimeSrv.makeAirportDateTime((item as Bid).takeoffDate, item.from).toISO({ includeOffset: false })),
          [Validators.required]
        ],
        from: curFromAirports,
        to: curToAirports,
        aircraftInfo: [(item as Bid).aircraftInfo],
        flightTimeHours: [
          +(item as Bid).flightTime.split(':')[0], [Validators.required, Validators.min(0), Validators.max(23)]
        ],
        flightTimeMinutes: [
          +(item as Bid).flightTime.split(':')[1] || '00', [Validators.required, Validators.min(0), Validators.max(59)]
        ],
        flightTimeDuration: [Duration.
          fromObject({ hours: +(item as Bid).flightTime.split(':')[0] || 0, minutes: +(item as Bid).flightTime.split(':')[1] || 0 })],
        price: [!itemIndex ? this.totalPrice() : 0, [Validators.required, Validators.min(itemIndex ? 0 : 1), this.validateNumField]],
        emptyLeg: [(item as Bid).emptyLeg, Validators.required],
        priceCurCode: [(item as Bid).priceCurCode, Validators.required],
        expirationDate: [item.expirationDate],
        description: [item.description],
        takeoffDate: [this.airportDatetimeSrv.makeAirportDateTime((item as Bid).takeoffDate, item.from)],
        landingDate: [this.airportDatetimeSrv.makeAirportDateTime((item as Bid).landingDate, item.to)],
        takeoffTime: [
          this.airportDatetimeSrv.makeAirportDateTime((item as Bid).takeoffDate, item.from).toFormat('HH:mm'),
          [Validators.required]
        ],
        landingTime: [
          this.airportDatetimeSrv.makeAirportDateTime((item as Bid).landingDate, item.to).toFormat('HH:mm'), Validators.required
        ],
        takeoffOffset: [this.airportDatetimeSrv.makeAirportDateTime((item as Bid).takeoffDate, item.from).toFormat('ZZ')],
        landingOffset: [this.airportDatetimeSrv.makeAirportDateTime((item as Bid).landingDate, item.to).toFormat('ZZ')],
      }, {
        validators: [
          this.checkMatchAirports,
          this.pastDateTimeValidator,
        ]
      }) : this.fb.group({
        id: [item.id || ''],
        refNumber: [undefined],
        flightDate: [
          new Date(this.airportDatetimeSrv.makeAirportDateTime((item as Leg).flightDate, item.from).toISO({ includeOffset: false })),
          [Validators.required]
        ],
        from: curFromAirports,
        to: curToAirports,
        aircraftInfo: [undefined, Validators.required],
        flightTimeHours: [undefined, [Validators.required, Validators.min(0), Validators.max(23)]],
        flightTimeMinutes: ['00', [Validators.required, Validators.min(0), Validators.max(59)]],
        flightTimeDuration: [undefined],
        price: [itemIndex ? 0 : undefined, [Validators.required, Validators.min(itemIndex ? 0 : 1), this.validateNumField]],
        emptyLeg: [false],
        priceCurCode: [(item as Leg).budgetCurCode, Validators.required],
        expirationDate: [undefined],
        description: ['This price is subject to aircraft and crew availability at the time of booking, airport slots, parking, permissions and schedule.\n**NOTE: Airport slots and permissions will be checked only after Charter Agreement is signed. Please note as this may affect the requested departure times. Charges for any extension of normal airport or handling hours, hangarage (if required) not included.'],
        serviceInfo: [undefined],
        takeoffDate: [this.airportDatetimeSrv.makeAirportDateTime((item as Leg).flightDate, item.from)],
        landingDate: [''],
        takeoffTime: [
          this.airportDatetimeSrv.makeAirportDateTime((item as Leg).flightDate, item.from).toFormat('HH:mm'),
          [Validators.required]
        ],
        landingTime: ['', Validators.required],
        takeoffOffset: [this.airportDatetimeSrv.makeAirportDateTime((item as Leg).flightDate, item.from).toFormat('ZZ')],
        landingOffset: [this.airportDatetimeSrv.makeAirportDateTime((item as Leg).flightDate, item.to).toFormat('ZZ')],
      }, {
        validators: [
          this.checkMatchAirports,
          this.pastDateTimeValidator,
        ]
      });
      curGroup.get('from')?.setValidators([Validators.required, this.airportValidator]);
      curGroup.get('to')?.setValidators([Validators.required, this.airportValidator]);
      this.watchTimeChange(curGroup, 'flightTimeHours');
      this.watchTimeChange(curGroup, 'flightTimeMinutes');
      if (this.isEditing && !this.bookingId) this.hideFeaturedDealButton = !!this.pastDateTimeValidator(curGroup, false)?.pastdate;
      this.bidsList.push(curGroup);
    });
    if (this.isAuthorized) {
      if (this.userService.isOperator) {
        this.getAircrafts().subscribe();
        this.getFlightEstimateByLegs();
      } else {
        this.bidsForm.get('operator')?.valueChanges
          .subscribe(value => {
            this.operatorsFiltered = this._filter(value);
            if (value?.id) this.getOperatorAircrafts(value.id).subscribe();
          });
        if (this.isEditing) this.bidsForm.get('operator')?.setValue((this.items[0] as Bid).aircraftInfo?.operator);
      }
    }
  }

  checkMatchAirports = (frm: AbstractControl) => {
    const from = frm.get('from')?.value;
    const to = frm.get('to')?.value;
    if (from?.id && to?.id && from.id === to.id) {
      return { matchAirports: true };
    }
    return null;
  };

  watchTimeChange(curGroup: UntypedFormGroup, key: string): void {
    curGroup.get(key)?.valueChanges
      .pipe(debounceTime(100))
      .subscribe(() => {
        curGroup.get('flightTimeHours')?.setErrors(
          (typeof curGroup.value.flightTimeHours === 'number' && curGroup.value.flightTimeHours < 24) ? null : { hoursLimit: true });
        curGroup.get('flightTimeMinutes')?.setErrors(
          ((typeof curGroup.value.flightTimeMinutes === 'number'
            && curGroup.value.flightTimeMinutes < 60)
            || curGroup.value.flightTimeMinutes === '00') ? null : { minutesLimit: true }
        );
        if (+curGroup.value.flightTimeHours < 1 && +curGroup.value.flightTimeMinutes < 1) {
          curGroup.get('flightTimeHours')?.setErrors({ bothTimesIsZero: true });
          curGroup.get('flightTimeMinutes')?.setErrors({ bothTimesIsZero: true });
        }
        curGroup.patchValue({
          flightTimeDuration: Duration.
            fromObject({ hours: +curGroup.value.flightTimeHours || 0, minutes: +curGroup.value.flightTimeMinutes || 0 })
        });
        this.setTotalTime();
        this.setLandingTime(curGroup);
      });
  }

  airportValidator(formControl: AbstractControl): ValidationErrors | null {
    const isValid = formControl.value?.id && formControl.value.countryCode;
    return isValid ? null : { whitespace: true };
  }

  pastDateTimeValidator = (formControl: AbstractControl, showErrors = true) => {
    const formData = formControl.value;
    if (this.bookingId || (formData.flightDate instanceof Date) === false || isNaN(formData.flightDate) || !formData?.from?.id) return null;

    let isValid = true;

    const takeoffHours = +formData.takeoffTime.split(':')[0];
    const takeoffMinutes = +formData.takeoffTime.split(':')[1];
    const flightDateTime = DateTime.now()
      .setZone(this.airportDatetimeSrv.getTimeZoneFromAirport(formData.from))
      .set({
        year: +formData.flightDate.getFullYear(),
        month: +formData.flightDate.getMonth() + 1,
        day: +formData.flightDate.getDate(),
        hour: takeoffHours,
        minute: takeoffMinutes,
      });
    const localNow = this.airportDatetimeSrv.getAirportCurDateTime(formData.from);
    isValid = flightDateTime.toISO() >= localNow.toISO();
    if (showErrors) {
      formControl.get('flightDate')?.setErrors(isValid ? null : { pasttime: true });
      formControl.get('takeoffTime')?.setErrors(isValid ? null : { pasttime: true });
    }
    return isValid ? null : { pastdate: true };
  };

  getFlightEstimateByLegs(): void {
    const arr = [];
    for (let i = 0; i < this.bidsList.length; i++) {
      const bid = this.bidsList.at(i);
      if (bid.value.from?.id && bid.value.to?.id) {
        arr.push({
          flightDate: bid.value.flightDate,
          fromId: bid.value.from?.id,
          toId: bid.value.to?.id,
        });
      }
    }
    if (arr.length) {
      this.ersService.getFlightEstimateByLegs({ legs: arr })
        .pipe(first())
        .subscribe(resp => {
          this.estimatedFlightTime = resp.legs.map((leg: EstimatedLeg) => {
            const obj: any = {};
            leg.categories.forEach((category: EstimatedLegByCategory) => {
              if (category.estimateV2) {
                const hours = Math.floor(category.estimateV2.durationInHours);
                const minutes = Math.floor((category.estimateV2.durationInHours - hours) * 60);
                obj[category.name] = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
              }
            });
            return obj;
          });
        });
    }
  }

  getAircrafts(): Observable<AircraftSaved[]> {
    return this.aircraftsSavedService.getAircraftsSaved()
      .pipe(
        first(),
        map(savedAircrafts => savedAircrafts.filter(aircraft => aircraft.active)),
        tap(savedAircrafts => {
          this.aircraftsSaved = savedAircrafts;
          this.fillSelectedAircrafts();
        })
      );
  }

  getOperatorAircrafts(operatorId: string): Observable<AircraftSaved[]> {
    return this.aircraftsSavedService.getAircraftsByOperatorId(operatorId)
      .pipe(
        first(),
        map(savedAircrafts => savedAircrafts.filter(aircraft => aircraft.active)),
        tap(savedAircrafts => {
          this.aircraftsSaved = savedAircrafts;
          this.fillSelectedAircrafts();
        })
      );
  }

  fillSelectedAircrafts(): void {
    for (let i = 0; i < this.bidsList.length; i++) {
      if (this.bidsList.at(i).value.aircraftInfo?.id) {
        const aircraftInfo = this.aircraftsSaved.find(aircraft => {
          return aircraft.id === this.bidsList.at(i).value.aircraftInfo?.id;
        });
        (this.bidsList.at(i) as UntypedFormGroup).controls.aircraftInfo.setValue(aircraftInfo);
      }
    }
  }

  fillEmptyAircrafts(event: MatSelectChange): void {
    for (let i = 0; i < this.bidsList.length; i++) {
      if (!this.bidsList.at(i).value.aircraftInfo) {
        const aircraftInfo = this.aircraftsSaved.find(aircraft => {
          return aircraft.id === (typeof event === 'number' && event || event.value.id);
        });
        (this.bidsList.at(i) as UntypedFormGroup).controls.aircraftInfo.setValue(aircraftInfo);
      }
    }
  }

  bindAirports(control: AbstractControl, type: string): void {
    control.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(value => {
        if (!value || value instanceof Object) return;
        this.errorMsg = '';
        this.isLoading = true;
        if (type === 'from') this.filteredFromAirports = [];
        if (type === 'to') this.filteredToAirports = [];

        this.airportsService.searchAirports(value)
          .pipe(first())
          .subscribe(resp => {
            if (resp.airports?.length) {
              this.errorMsg = '';
              if (type === 'from') this.filteredFromAirports = resp.airports;
              if (type === 'to') this.filteredToAirports = resp.airports;
            } else {
              this.errorMsg = 'Error';
              if (type === 'from') this.filteredFromAirports = [];
              if (type === 'to') this.filteredToAirports = [];
            }
            this.isLoading = false;
          }, err => this.isLoading = false);
      });
  }

  focusAirport(event: FocusEvent): void {
    (event.target as HTMLInputElement).setSelectionRange(2, (event.target as HTMLInputElement).value.length);
  }

  getCountryFlagClass(countryCode: string): string {
    if (!countryCode) return '';
    return `fi-${countryCode.toLowerCase()}`;
  }

  displayFn(airport: Airport): string {
    return airport?.name ? `${airport.icao}  ${airport.name}` : '';
  }

  displayOperatorFn(operator: Operator): string {
    return operator?.name || '';
  }

  _filter(value: Operator | string): Operator[] {
    if((value as Operator)?.name) return this.operators;
    const filterValue = (value as string)?.toLowerCase();
    if (filterValue) {
      return this.operators?.filter(operator => {
        return operator.name.toLowerCase().includes(filterValue) || this.getOperatorEmailAndPhonePipe.transform(operator).email.toLowerCase().includes(filterValue)
      });
    } else {
      return this.operators;
    }
  }

  onSubmit(): void {
    if (this.bidsForm.valid) {
      if (!this.isHronologicLegsDates) {
        return this.snack.systemError('Please enter the dates of your suggestions in chronological order', 6000);
      }
      if (this.submitState || this.isDraftOffer) {
        if (this.isEditing || this.isDraftOffer) {
          if (this.bookingId) this.editBooking();
          else this.edit();
        }
        else this.send();
      }
      else { this.submitState = true; }
    }
    else { this.snack.systemError('Please complete the required fields', 5000); }
  }

  send(): void {
    this.requesting = true;
    const tempBidsToCreate: Array<Record<any, any>> = JSON.parse(JSON.stringify([...this.bidsForm.value.bidsList]));
    const bidsToCreate: Array<BidToCreate> = [];
    tempBidsToCreate.forEach(tempBidToCreate => {
      const curBidToCreate = new BidToCreate(tempBidToCreate);
      if (!this.userService.isOperator) curBidToCreate.operatorId = this.bidsForm.value.operator?.id;
      bidsToCreate.push(curBidToCreate);
    });
    this.handleRequest(this.bidsService.createBid(bidsToCreate));
  }

  edit(): void {
    this.requesting = true;
    const tempBidsToUpdate: Array<Record<any, any>> = JSON.parse(JSON.stringify([...this.bidsForm.value.bidsList]));
    const bidsToUpdate: Array<BidToUpdate> = [];
    tempBidsToUpdate.forEach(tempBidToCreate => {
      const curBidToUpdate = new BidToUpdate(tempBidToCreate);
      if (this.isDraftOffer) {
        curBidToUpdate.status = 'new';
        curBidToUpdate.draft = false;
      }
      if (!this.userService.isOperator) curBidToUpdate.operatorId = this.bidsForm.value.operator?.id;
      bidsToUpdate.push(curBidToUpdate);
    });
    this.handleRequest(this.bidsService.updateBid(bidsToUpdate));
  }

  editBooking(): void {
    this.requesting = true;
    const tempBidsToUpdate: Array<Record<any, any>> = JSON.parse(JSON.stringify([...this.bidsForm.value.bidsList]));
    const bookingLegsToUpdate: Array<BookingToUpdate> = [];
    tempBidsToUpdate.forEach(tempBidToCreate => {
      const curBidToUpdate = new BookingToUpdate(tempBidToCreate);
      bookingLegsToUpdate.push(curBidToUpdate);
    });
    this.bookingService.updateBooking(this.bookingId, { operatorId: this.bidsForm.value.operator.id }).pipe(first()).subscribe();
    this.handleRequest(this.bookingService.updateBookingLegs(this.bookingId, bookingLegsToUpdate));
  }

  handleRequest(action: Observable<unknown>): void {
    action
      .pipe(first())
      .subscribe({
        next: () => {
          this.requesting = false;
          this.successState = true;
          this.bidsForm.reset();
          if (this.frpId) this.getOfferByFrpId(this.frpId);
        },
        error: (error) => {
          this.requesting = false;
          if (error.code === 'oj_0008') {
            alert(`This Flight request doesn't available now.`);
          } else {
            alert(error.message);
          }
        }
      });
  }

  back(): void {
    if (this.submitState) this.submitState = false;
  }

  setAdditionalInfoTemplate(event: KeyboardEvent, index: number): void {
    if (event.code === 'KeyN' && event.ctrlKey && event.altKey) {
      const bid = this.bidsList.at(index);
      bid.get('description')?.setValue((bid.value.description.trim() ? `${bid.value.description}\n` : '') + `Our offer is [EUR 0000]. The converted ${bid.value.priceCurCode} price is a courtesy exchange only based on today's exchange rate. The actual exchange rate will be applied at the date and time of your booking.\n\n\nThis price is subject to availability, slots, traffic rights and schedule. Please do not hesitate to contact us if you need any further information.`);
    }
  }

  setTotalTime(): void {
    let totalDuration = Duration.fromMillis(0);
    this.bidsList.controls.forEach(itemForm => {
      totalDuration = totalDuration.plus(!itemForm.value.flightTimeDuration?.invalid && itemForm.value.flightTimeDuration || 0);
    });
    this.totalTime = totalDuration.toFormat('hh:mm');
  }

  totalPrice(): number {
    let result = 0;
    this.items.forEach(item => result = result + (+(item as Bid).price || 0));
    return result;
  }

  setOffset(airport: Airport, item: AbstractControl, waypoint: string): void {
    const curDate = (item as UntypedFormGroup).value.flightDate;
    item.patchValue({ [`${waypoint}Offset`]: this.airportDatetimeSrv.makeAirportDateTime(curDate, airport).toFormat('ZZ') });
  }

  validateNumField(control: AbstractControl): { error: string; } | null {
    const rem = control.value && Number.parseFloat(control.value) % 1;
    /**
     * If the remainder is not 0 then user has entered an invalid value
     */
    return rem ? { error: 'Not a valid step' } : null;
  }

  setLandingTime(item: AbstractControl): void {
    if (!item.value.from || !item.value.to) return;
    const flightHoursDuration = (item as UntypedFormGroup).value.flightTimeHours || 0;
    const flightMinsDuration = (item as UntypedFormGroup).value.flightTimeMinutes || 0;
    if (isNaN(+flightHoursDuration) || isNaN(+flightMinsDuration)) return;

    const takeoffD = (item as UntypedFormGroup).value.takeoffDate;
    const takeoffAirport = (item as UntypedFormGroup).value.from;
    const landingAirport = (item as UntypedFormGroup).value.to;

    const takeoffDT: DateTime = this.airportDatetimeSrv.makeAirportDateTime(takeoffD, takeoffAirport);
    const landingDT: DateTime = this.airportDatetimeSrv.makeAirportDateTime(takeoffDT, landingAirport)
      .plus({ hours: +flightHoursDuration, minutes: +flightMinsDuration });
    (item as UntypedFormGroup).patchValue({
      landingDate: landingDT,
      landingTime: landingDT.toFormat('HH:mm'),
      landingOffset: landingDT.toFormat('ZZ'),
    });
  }

  timingsRecalculate(airport: Airport, item: AbstractControl, waypoint: string): void {
    this.setOffset(airport, item, waypoint);
    if (this.bookingId) {
      (item as UntypedFormGroup).patchValue({
        landingDate: undefined,
        landingTime: '',
        flightTimeHours: '',
        flightTimeMinutes: ''
      });
    } else {
      this.setLandingTime(item);
    }
  }

  onDateChanged(item: AbstractControl): void {
    if (item.value.from) {
      let curDate = item.value.flightDate;
      if (!(curDate instanceof Date)) curDate = new Date(curDate);
      const curTakeoffDate = this.airportDatetimeSrv.makeAirportDateTime(item.value.takeoffDate, item.value.from)
        .set({ year: curDate.getFullYear() })
        .set({ month: curDate.getMonth() + 1 })
        .set({ day: curDate.getDate() });
      (item as UntypedFormGroup).patchValue({
        flightDate: new Date(curTakeoffDate.toISO({ includeOffset: false })),
        takeoffDate: curTakeoffDate,
        takeoffTime: curTakeoffDate.toFormat('HH:mm'),
        takeoffOffset: curTakeoffDate.toFormat('ZZ'),
      });
      this.setOffset(item.value.from, item, 'takeoff');
      this.setOffset(item.value.to, item, 'landing');
      this.setLandingTime(item);
    } else {
      const curTakeoffDate = DateTime.fromJSDate(item.value.flightDate)
        .set({ year: item.value.flightDate.getFullYear() })
        .set({ month: item.value.flightDate.getMonth() + 1 })
        .set({ day: item.value.flightDate.getDate() })
        .set({ hour: item.value.takeoffTime.split(':')[0] || 0 })
        .set({ minute: item.value.takeoffTime.split(':')[1] || 0 });
      (item as UntypedFormGroup).patchValue({
        flightDate: new Date(curTakeoffDate.toISO({ includeOffset: false })),
        takeoffDate: curTakeoffDate,
      });
    }
  }

  onTimeChanged(event: string, item: AbstractControl): void {
    if (!item.value.from) return;
    const takeoffHours = +event.split(':')[0];
    const takeoffMinutes = +event.split(':')[1];
    const curTakeoffDate = this.airportDatetimeSrv.makeAirportDateTime(item.value.takeoffDate, item.value.from)
      .set({ hour: takeoffHours })
      .set({ minute: takeoffMinutes });
    (item as UntypedFormGroup).patchValue({
      takeoffDate: curTakeoffDate,
      takeoffTime: curTakeoffDate.toFormat('HH:mm'),
      takeoffOffset: curTakeoffDate.toFormat('ZZ'),
    });
    this.setOffset(item.value.from, item, 'takeoff');
    this.setOffset(item.value.to, item, 'landing');
    this.setLandingTime(item);
  }

  onManualLandingTimeChanged(event: string, item: AbstractControl): void {
    const landingHours = +event.split(':')[0];
    const landingMinutes = +event.split(':')[1];
    const takeoffAirport = (item as UntypedFormGroup).value.from;
    const landingAirport = (item as UntypedFormGroup).value.to;
    const takeoffDT: DateTime = this.airportDatetimeSrv.makeAirportDateTime((item as UntypedFormGroup).value.takeoffDate, takeoffAirport);
    const landingDT: DateTime = this.airportDatetimeSrv.makeAirportDateTime(takeoffDT, landingAirport)
      .set({ hour: landingHours })
      .set({ minute: landingMinutes });
    const flightTime = landingDT.diff(takeoffDT);
    let time = flightTime.toFormat('hh:mm');
    if (flightTime.valueOf() < 0) {
      time = Duration.fromMillis(86400000 + flightTime.valueOf()).toFormat('hh:mm');
    }
    (item as UntypedFormGroup).patchValue({
      flightTimeHours: +time.split(':')[0],
      flightTimeMinutes: +time.split(':')[1]
    });
  }

  formatTime(time: string | number): string | number {
    if (!time) return '00';
    if ((time as string).length === 2) return time;
    if (time as number < 10) return '0' + time;
    return time;
  }

  openAddAircraft(i: number): void {
    const dialogRef = this.dialog.open(AircraftSavedDetailsComponent, {
      panelClass: ['no-padding-dialog', 'mob-full-dialog'],
      disableClose: true,
      autoFocus: false,
      data: { operatorId: this.bidsForm.value?.operator?.id }
    });
    dialogRef.afterClosed().subscribe(aircraftId => {
      if (aircraftId) {
        (this.bidsList.at(i) as UntypedFormGroup).controls.aircraftInfo.setValue({ id: aircraftId });
        if (this.userService.isOperator) {
          this.getAircrafts().subscribe(() => this.fillEmptyAircrafts(aircraftId));
        } else {
          this.getOperatorAircrafts(this.bidsForm.value.operator.id).subscribe(() => this.fillEmptyAircrafts(aircraftId));
        }
      }
    });
  }

  openEditAircraft(id: string): void {
    const dialogRef = this.dialog.open(AircraftSavedDetailsComponent, {
      panelClass: ['no-padding-dialog', 'mob-full-dialog'],
      disableClose: true,
      autoFocus: false,
      data: { operatorId: this.bidsForm.value?.operator?.id, id }
    });
    dialogRef.afterClosed().subscribe(aircraftId => {
      if (aircraftId) {
        if (this.userService.isOperator) {
          this.getAircrafts().subscribe(() => this.fillEmptyAircrafts(aircraftId));
        } else {
          this.getOperatorAircrafts(this.bidsForm.value.operator.id).subscribe(() => this.fillEmptyAircrafts(aircraftId));
        }
      }
    });
  }

  cancelOffer(): void {
    if (this.offerId) this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'CANCEL OFFER',
        text: 'Are you sure you wish to cancel this offer?',
        confirm: () => {
          this.offersService.cancelOffer(this.offerId)
          .pipe(first())
          .subscribe(resp => {
            this.router.navigate(['/requests/progress'], { queryParams: { request: this.frpId } })
          });
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false,
    });
  }

  addCurrencyConversion(i: number): void {
    const bid = this.bidsList.at(i);
    bid.get('description')?.setValue(`Our offer is [EUR 0000]. The converted ${bid.value.priceCurCode} price is a courtesy exchange only based on today's exchange rate. The actual exchange rate will be applied at the date and time of your booking.\n\n\n` + (bid.value.description.trim() ? `${bid.value.description}` : ''));
  }

  updateScrollOne(): void {
    if (this.onScrollTwo || !this.scrollTwo) return;
    this.updateScroll(this.scrollOne.nativeElement, this.scrollTwo.nativeElement);
  }

  updateScrollTwo(): void {
    if (this.onScrollOne) return;
    this.updateScroll(this.scrollTwo.nativeElement, this.scrollOne.nativeElement);
  }

  updateScroll(scrollOne: HTMLElement, scrollTwo: HTMLElement): void {
    const percents = scrollOne.scrollTop / (scrollOne.scrollHeight - scrollOne.offsetHeight);
    scrollTwo.scrollTop = Math.round((scrollTwo.scrollHeight - scrollTwo.offsetHeight) * percents);
  }

  get isHronologicLegsDates(): boolean {
    let correct = true;
    for (let i = 0; i < this.bidsList.length - 1; i++) {
      for (let j = i + 1; j < this.bidsList.length; j++) {
        if (this.bidsList.at(i).value.takeoffDate > this.bidsList.at(j).value.takeoffDate) {
          correct = false;
          break;
        }
      }
    }
    return correct;
  }

  get isOperator(): boolean {
    return this.userService.isOperator;
  }

  get isDraftOffer(): boolean {
    return this.items.every(item => (item as Bid).status === 'draft' || (item as Bid).draft);
  }

  get clientBudget(): number {
    return this.legs.map(leg => leg.budget).reduce((sum, a) => sum + a, 0) || 0;
  }

}
