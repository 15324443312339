export const environment = {
  production: false,
  baseUrl: 'https://ops.it4biz.com.ua/api/admin/v1',
  firebase: {
    apiKey: 'AIzaSyBz3ljDk_CkGvT-_nqvy_C9IBv3DjfUt50',
    authDomain: 'opesp-64474.firebaseapp.com',
    databaseURL: 'https://opesp-64474.firebaseio.com',
    projectId: 'opesp-64474',
    storageBucket: 'opesp-64474.appspot.com',
    messagingSenderId: '141305550251',
    appId: '1:141305550251:web:15ec24d70155f26d56aeee',
    measurementId: 'G-8P8RS729MS'
  },
  loggerUrl: 'https://fl-dev.it4biz.com.ua:14141',
};
