import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SidebarDirective } from './components/directives/sidebar.directive';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TimeMaskDirective } from './components/directives/time-mask.directive';
import { AircraftSavedDetailsComponent } from './components/modals/aircraft-saved-details/aircraft-saved-details.component';
import { PassengersComponent } from './components/modals/passengers/passengers.component';
import { LightboxModule } from 'ng-gallery/lightbox';
import { GalleryModule } from 'ng-gallery';
import { OtherOffersComponent } from './components/modals/other-offers/other-offers.component';
import { AirportDateTime } from './pipes/AirportDateTime';
import { BookingStatusPipe } from './pipes/BookingStatus';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { FlightProgressComponent } from './components/flight-progress/flight-progress.component';
import { ActionAlertComponent } from './components/modals/action-alert/action-alert.component';
import { SmoothScrollDirective } from './components/directives/smooth-scroll.directive';
import { CustomDateAdapter } from './classes/custom-date-adapter';
import { FilterArrayPipe } from './pipes/FilterArray';
import { CentsToDollarsPipe } from './pipes/CentsToDollars';
import { PassengersTypePipe } from './pipes/PassengersType';
import { PopupDefaultComponent } from './components/popups/popup-default/popup-default.component';
import { GetOperatorEmailAndPhone } from './pipes/GetOperatorEmailAndPhone';
import { BillingInfoComponent } from './components/widgets/billing-info/billing-info.component';
import { BookingComponent } from './components/widgets/booking/booking.component';
import { ClientInfoComponent } from './components/widgets/client-info/client-info.component';
import { EditPhoneComponent } from './components/widgets/client-info/edit-phone/edit-phone.component';
import { FeaturedDealComponent } from './components/widgets/featured-deal/featured-deal.component';
import { RequestComponent } from './components/widgets/request/request.component';
import { TagsComponent } from './components/widgets/tags/tags.component';
import { RenameTabComponent } from './components/modals/rename-tab/rename-tab.component';
import { OpesCoinComponent } from './components/widgets/opes-coin/opes-coin.component';
import { EditBalanceComponent } from './components/widgets/opes-coin/edit-balance/edit-balance.component';
import { NotAllowEditComponent } from './components/widgets/opes-coin/not-allow-edit/not-allow-edit.component';
import { GlobalSharedService } from './global-shared.service';
import { EditOperatorComponent } from './components/modals/edit-operator/edit-operator.component';
import { MatChipsModule } from '@angular/material/chips';
import { ChatComponent } from './components/modals/chat/chat.component';
import { BackDirective } from './components/directives/back.directive';
import { FlightPreferencesComponent } from './components/widgets/flight-preferences/flight-preferences.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { RenameDocumentComponent } from './components/modals/rename-document/rename-document.component';
import { RejectOfferComponent } from './components/modals/reject-offer/reject-offer.component';
import { CancelBookingComponent } from './components/modals/cancel-booking/cancel-booking.component';
import { ChecklistComponent } from './components/modals/checklist/checklist.component';

// tslint:disable-next-line:no-any
const components = [
  LoadingSpinnerComponent,
  FlightProgressComponent,
  ActionAlertComponent,
  CancelBookingComponent,
  ChecklistComponent,
  RenameDocumentComponent,
  RenameTabComponent,
  AircraftSavedDetailsComponent,
  PassengersComponent,
  BackDirective,
  SmoothScrollDirective,
  TimeMaskDirective,
  SidebarDirective,
  OtherOffersComponent,
  AirportDateTime,
  BookingStatusPipe,
  FilterArrayPipe,
  CentsToDollarsPipe,
  PassengersTypePipe,
  PopupDefaultComponent,
  RejectOfferComponent,
  GetOperatorEmailAndPhone,
  EditOperatorComponent,
  ChatComponent,

  /* widgets */
  BillingInfoComponent,
  BookingComponent,
  ClientInfoComponent,
  EditPhoneComponent,
  FeaturedDealComponent,
  FlightPreferencesComponent,
  OpesCoinComponent,
  EditBalanceComponent,
  NotAllowEditComponent,
  RequestComponent,
  TagsComponent,
];

// tslint:disable-next-line:no-any
const modules = [
  ClipboardModule,
  CommonModule,
  GalleryModule,
  LayoutModule,
  LightboxModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatChipsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  NgOptimizedImage,
  ReactiveFormsModule,
  RouterModule,
  NgxMapboxGLModule,
];

@NgModule({
  declarations: components,
  imports: modules,
  exports: [
    ...components,
    ...modules,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    BookingStatusPipe,
    CentsToDollarsPipe,
    GetOperatorEmailAndPhone,
    GlobalSharedService,
  ]
})
export class GlobalSharedModule { }
